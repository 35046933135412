import React, { useEffect, useRef, useState } from 'react'
import Container from '../container'

import * as styles from './cloudIndexNav.module.css'

const CloudIndexNav = ({ current, black, hide }) => {
  if (hide) return (<></>)

  const linksRef = useRef()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(prevIsMenuOpen => !prevIsMenuOpen)
  }

  useEffect(() => {
    const handleClick = (e) => {
      e.preventDefault()
      toggleMenu()
    }

    const activeLink = linksRef.current.querySelector(`a.${styles.active}`)
    if (activeLink) {
      if (window.innerWidth < 768) {
        linksRef.current.insertBefore(activeLink, linksRef.current.firstChild)
        activeLink.addEventListener('click', handleClick)
      }
    }

    return () => {
      if (activeLink) {
        activeLink.removeEventListener('click', handleClick)
      }
    }
  }, [current])

  return (
    <div className={`${styles.nav} ${black ? styles.black : ''}`}>
      <div className={styles.scroller}>
        <Container class={styles.containerPad}>
          <div ref={linksRef} className={`${styles.links} ${isMenuOpen ? styles.menuOpened : ''}`}>
            <a href='/infrared/' className={current === 'index' ? styles.active : ''}>The redpoint index</a>
            <a href='/infrared/constituents/' className={current === 'constituents' ? styles.active : ''}>Index Constituents</a>
            <a href='/infrared/report/' className={current === 'report' ? styles.active : ''}>The InfraRed Report</a>
            <a href='/infrared/100/' className={current === 'infrared' ? styles.active : ''}>InfraRed 100</a>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default CloudIndexNav
